import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  boyHowdy: {
    color: 'white',
    opacity: 0.2,
    textAlign: 'center',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    textShadow: '0 0 20px #ffffff',
  },
}));

const BoyHowdy = () => {
  const classes = useStyles();
  return (
    <Typography variant="h1" component="h1" className={classes.boyHowdy}>
      {`Boy Howdy`}
    </Typography>
  );
};

export default BoyHowdy;